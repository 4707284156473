import React from 'react';
import styled from 'styled-components';
import { useAmp } from 'next/amp';

import Footer from './footer';
import { ScrollProgress } from './scroll-progress';

import Nav from '~/components/nav';

const ContentWrapper = styled.div`
  max-width: 80rem;
  width: 90%;
  margin: 0 auto;
  padding-top: 20rem;
  min-height: 100vh;

  @media (max-width: 700px) {
    padding-top: 12rem;
  }
`;

const Layout: React.FC = ({ children }) => {
  const isAmp = useAmp();
  return (
    <>
      <Nav />
      {!isAmp && <ScrollProgress />}
      <ContentWrapper>{children}</ContentWrapper>
      <Footer />
    </>
  );
};

export default Layout;
