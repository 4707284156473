import React from 'react';
import styled from 'styled-components';

const StyledFooter = styled.footer`
  min-height: 8rem;
  width: 100vw;
  background: var(--purple);
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  text-align: center;
  padding: 1rem 5rem;
  font-size: 1.6rem;
`;

const Footer = () => (
  <StyledFooter>
    <p>&copy; {new Date().getFullYear()} 🤷‍♂️</p>
  </StyledFooter>
);

export default Footer;
