import React from 'react';
import App from 'next/app';
import NextError from 'next/error';
import * as Sentry from '@sentry/browser';

import Layout from '~/components/layout';
import Meta from '~/components/meta';

import '~/public/global.css';

Sentry.init({
  dsn: process.env.SENTRY,
  environment: process.env.NODE_ENV,
  release: `travandlos@${process.env.VERSION}_${process.env.BUILD_ID}`,
  enabled: process.env.NODE_ENV === 'production',
});

class MyApp extends App {
  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });

    super.componentDidCatch(error, errorInfo);
  }

  public componentDidMount = async () => {
    if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
      try {
        await navigator.serviceWorker.register('/sw.js');
        // eslint-disable-next-line no-console
        console.info('service worker registration successful');
      } catch (error) {
        console.warn('service worker registration failed', error);
      }
    }
  };

  public render() {
    const { Component, pageProps } = this.props;

    return (
      <>
        <Meta />
        {pageProps.statusCode ? (
          <NextError statusCode={pageProps.statusCode} />
        ) : (
          <Layout>
            <Component {...pageProps} />
          </Layout>
        )}
      </>
    );
  }
}

export default MyApp;
