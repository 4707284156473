import React from 'react';
import Head from 'next/head';
import { useAmp } from 'next/amp';

const Meta = () => {
  const isAmp = useAmp();
  return (
    <Head>
      <title>Late Nights with Trav and Los</title>
      <meta charSet="utf-8" />
      {!isAmp && (
        <meta
          name="viewport"
          content="initial-scale=1.0, width=device-width, viewport-fit=cover"
        />
      )}
      <link rel="manifest" href="/manifest.webmanifest" />
      <link
        rel="shortcut icon"
        type="image/png"
        href="/static/img/icon-16x16.png"
        sizes="16x16"
      />
      <link
        rel="shortcut icon"
        type="image/png"
        href="/static/img/icon-32x32.png"
        sizes="32x32"
      />
      <link
        rel="shortcut icon"
        type="image/png"
        href="/static/img/icon-64x64.png"
        sizes="64x64"
      />
      <meta name="description" content={process.env.DESCRIPTION} />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:400,500,700"
      />
    </Head>
  );
};

export default Meta;
