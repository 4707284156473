import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Button = styled.button`
  position: fixed;
  right: 2rem;
  appearance: none;
  height: 4rem;
  width: 4rem;
  background: none;
  border: none;
  padding: 0;
  z-index: 4;
  cursor: pointer;
  overflow: hidden;

  @media (min-width: 800px) {
    display: none;
  }
`;

const Lines = styled.span`
  width: 100%;
  height: 0.2rem;
  background: var(--hamburger-color);
  display: block;
  position: relative;
  border-radius: 0.5rem;
  transition: 150ms all ease;
  will-change: transform;
  top: ${props => (props.open ? 0 : '-1rem')};
  transform: ${props => (props.open ? 'rotate(135deg)' : '')};

  &::before,
  &::after {
    content: '';
    width: 100%;
    height: 0.2rem;
    position: absolute;
    left: 0;
    background: var(--hamburger-color);
    border-radius: 0.5rem;
    transition: 150ms all ease;
    will-change: transform;
  }

  &::before {
    top: ${props => (props.open ? '0' : '1rem')};
    visibility: ${props => (props.open ? 'hidden' : 'visible')};
    opacity: ${props => (props.open ? 0 : 1)};
    transform: ${props => (props.open ? 'rotate(-135deg)' : '')};
  }

  &::after {
    top: ${props => (props.open ? 0 : '2rem')};
    transform: ${props => (props.open ? 'rotate(-270deg)' : '')};
  }
`;

const Hammy = ({ onClick, open }) => (
  <Button onClick={onClick}>
    <Lines open={open} />
  </Button>
);

Hammy.propTypes = {
  onClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default Hammy;
