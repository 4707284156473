import React from 'react';
import styled from 'styled-components';

const useScrollProgress = () => {
  const [scrollProgress, setScrollProgress] = React.useState(0);

  React.useEffect(() => {
    const handleScroll = () => {
      const scroll = window.pageYOffset; // window.scrollY is less supported
      const bodyHeight = document.body.offsetHeight;
      const windowHeight = window.innerHeight;
      const scrollPercent = (scroll / (bodyHeight - windowHeight)) * 100;
      const maxMinscroll = Math.min(100, Math.max(0, scrollPercent));
      setScrollProgress(maxMinscroll);
    };
    window.addEventListener('scroll', handleScroll, { passive: true });
    handleScroll();
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return scrollProgress;
};

const ProgressBar = styled.progress.attrs({ max: 100, min: 0 })`
  position: fixed;
  top: 9.6rem;
  left: 0;
  right: 0;
  width: 100%;
  height: 0.3rem;
  appearance: none;
  border: none;
  background: none;
  z-index: 1;

  @media (max-width: 700px) {
    top: 0;
    z-index: 2;
  }

  &::-webkit-progress-bar {
    background-color: transparent;
  }

  &::-webkit-progress-value {
    background-color: var(--purple);
  }

  &::-moz-progress-bar {
    background-color: var(--purple);
  }
`;

const ScrollProgress = () => {
  const scrollProgress = useScrollProgress();
  return <ProgressBar value={scrollProgress} />;
};

export { ScrollProgress };
