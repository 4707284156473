import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import isAbsoluteUrl from 'is-absolute-url';
import { useAmp } from 'next/amp';

import Hammy from './hamburger-menu';

const NavWrap = styled.nav`
  z-index: 2;
  height: 9.6rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: var(--background);
  box-shadow: var(--shadow);

  .logo {
    position: fixed;
    top: 0;
    left: 0;
    height: 20rem;
    width: 20rem;
    z-index: 2;

    @media (max-width: 700px) {
      height: 9.6rem;
      width: 9.6rem;
    }
  }

  a {
    transition: 300ms all ease-in-out;
    text-decoration: none;
    color: CurrentColor;
  }

  ol {
    padding: 0;
    margin-left: 20rem;
    list-style: decimal;
    display: flex;
    font-weight: 500;

    @media (max-width: 799px) {
      position: absolute;
      top: ${props => (props.open ? '6em' : '-20em')};
      transition: 300ms top ease-in-out;
      background: var(--background);
      width: 100vw;
      margin-left: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-shadow: var(--shadow);
    }
  }

  li {
    margin-left: 4rem;
    color: var(--text);
    font-size: 1.6rem;

    @media (max-width: 799px) {
      margin: 1rem 0;
      font-size: 1.3rem;
    }
  }

  img,
  amp-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const links = [
  { title: 'About', slug: '/#about' },
  { title: 'Episodes', slug: '/#episodes' },
  { title: 'Donate', slug: '/#donate' },
  { title: 'Swag', slug: '/#swag' },
  { title: 'Instagram', slug: 'https://instagram.com/loganmcansh' },
];

const Nav = () => {
  const isAmp = useAmp();

  const [navOpen, setNavOpen] = React.useState(false);

  const toggleHammy = () => setNavOpen(o => !o);

  return (
    <NavWrap open={navOpen}>
      <Link href="/">
        <a className="logo">
          {isAmp ? (
            <amp-img
              width={200}
              height={200}
              src="/static/img/Trav_and_Los_avatar_Final.png"
              alt="Trav and Los logo"
              layout="responsive"
            />
          ) : (
            <picture>
              <source
                srcSet="/static/img/Trav_and_Los_avatar_Final.webp"
                alt="Trav and Los logo"
                type="image/webp"
              />
              <source
                srcSet="/static/img/Trav_and_Los_avatar_Final.png"
                type="image/png"
              />
              <img
                src="/static/img/Trav_and_Los_avatar_Final.png"
                alt="Trav and Los logo"
              />
            </picture>
          )}
        </a>
      </Link>
      <ol>
        {links.map(({ slug, title }) => {
          const isExternal = isAbsoluteUrl(slug);
          const target = isExternal ? '_blank' : undefined;
          const rel = isExternal ? 'external nofollow' : undefined;
          return (
            <li key={title}>
              <Link href={slug} prefetch={isExternal ? false : undefined}>
                <a target={target} rel={rel}>
                  {title}
                </a>
              </Link>
            </li>
          );
        })}
      </ol>
      <Hammy onClick={toggleHammy} open={navOpen} />
    </NavWrap>
  );
};

export default Nav;
